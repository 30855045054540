import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import BubbleMapViewer from "./BubbleMapViewer";

function App() {
  return (
    <Router>
      <Routes>
        {/* Standard Access Routes */}
        <Route path="/" element={<BubbleMapViewer />} />
        <Route path="/bundles/:address" element={<BubbleMapViewer />} />

        {/* Iframe Access Route */}
        <Route
          path="/iframe/bundles/:address"
          element={<BubbleMapViewer isIframe={true} />}
        />
      </Routes>
    </Router>
  );
}

export default App;
