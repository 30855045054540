import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { toPng } from "html-to-image";
import * as d3 from "d3";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "react-tooltip";
import "./index.css";
import "react-tooltip/dist/react-tooltip.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faRotateRight,
  faBox,
  faMoneyBill,
  faChartLine,
  faHand,
  faLink,
  faCameraRetro,
  faCheck,
  faTimes,
  faTag,
  faCopy,
  faMoon,
  faSun,
  faSeedling,
  faCrosshairs,
  faHandsHelping,
  faUser,
  faUserGroup,
  faInfoCircle,
  faTimesCircle,
  faExclamationTriangle,
  faCoins,
  faPercent,
  faExclamationCircle,
  faArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { faTelegram, faXTwitter } from "@fortawesome/free-brands-svg-icons";

library.add(
  faTelegram,
  faXTwitter,
  faSearch,
  faRotateRight,
  faBox,
  faMoneyBill,
  faChartLine,
  faHand,
  faLink,
  faCameraRetro,
  faCheck,
  faTimes,
  faTag,
  faCopy,
  faMoon,
  faSun,
  faSeedling,
  faCrosshairs,
  faHandsHelping,
  faUser,
  faUserGroup,
  faInfoCircle,
  faTimesCircle,
  faExclamationTriangle,
  faCoins,
  faPercent,
  faExclamationCircle,
  faArrowUp
);

const categoryDescriptionMap = {
  new_wallet: "Fresh wallet (<1 day or <25 transactions)",
  sniper: "2000+ pump.fun transactions or high ratio",
  copytrader: "2+ wallets with 60%+ matching history",
  team_bundle: "Project team bundle",
  regular: "Balanced pump.fun to Raydium ratio",
};

const getCategoryIcon = (category, withTooltip = false) => {
  const tooltipProps = withTooltip
    ? {
        "data-tooltip-id": "category-tooltip",
        "data-tooltip-content": categoryDescriptionMap[category],
      }
    : {};

  const iconStyle = {
    marginRight: "4px",
    display: "inline-block",
    verticalAlign: "middle",
  };

  const renderIcon = (icon) => (
    <FontAwesomeIcon icon={icon} style={iconStyle} {...tooltipProps} />
  );

  switch (category) {
    case "new_wallet":
      return renderIcon(faSeedling);
    case "sniper":
      return renderIcon(faCrosshairs);
    case "copytrader":
      return renderIcon(faHandsHelping);
    case "team_bundle":
      return renderIcon(faUserGroup);
    default:
      return renderIcon(faUser);
  }
};

const BubbleMapViewer = ({ isIframe }) => {
  const { address: addressParam } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [inputAddress, setInputAddress] = useState("");
  const [bundleData, setBundleData] = useState(null);
  const [selectedBundle, setSelectedBundle] = useState(null);
  const [zoom, setZoom] = useState(1);
  const [pan, setPan] = useState({ x: 0, y: 0 });

  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const customStyles = useMemo(() => {
    let styles = {};
    if (params.get("customStyles")) {
      try {
        styles = JSON.parse(params.get("customStyles"));
      } catch (e) {
        console.error("Failed to parse customStyles:", e);
      }
    }
    return styles;
  }, [params]);

  useEffect(() => {
    if (customStyles.containerBackground) {
      document.documentElement.style.setProperty(
        "--container-background",
        customStyles.containerBackground
      );
    }
    if (customStyles.bubbleColor) {
      document.documentElement.style.setProperty(
        "--bubble-color",
        customStyles.bubbleColor
      );
    }
  }, [customStyles]);

  const containerRef = useRef(null);
  const simulationRef = useRef(null);
  const previousAddressRef = useRef(null);
  const [nodes, setNodes] = useState([]);
  const [containerSize, setContainerSize] = useState({
    width: 1000,
    height: 1000,
  });

  const updateSize = useCallback(() => {
    if (containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      setContainerSize({ width, height });
    }
  }, []);

  const [darkMode, setDarkMode] = useState(() => {
    if (isIframe) {
      return true;
    }
    const savedDarkMode = localStorage.getItem("darkMode");
    return savedDarkMode ? JSON.parse(savedDarkMode) : true;
  });

  const showAllFlag = params.get("all") === "true";

  const [hideEmptyBundles, setHideEmptyBundles] = useState(!showAllFlag);
  const [isLoading, setIsLoading] = useState(false);
  const [hasVisibleBubbles, setHasVisibleBubbles] = useState(false);

  const [draggingNode, setDraggingNode] = useState(null);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0, fx: 0, fy: 0 });
  const [isDragging, setIsDragging] = useState(false);

  const [showNewWallet, setShowNewWallet] = useState(true);
  const [showSniper, setShowSniper] = useState(true);
  const [showCopyTrader, setShowCopyTrader] = useState(true);
  const [showRegular, setShowRegular] = useState(true);
  const [showTeamBundle, setShowTeamBundle] = useState(true);

  const [showCreatorAnalysis, setShowCreatorAnalysis] = useState(false);
  const toggleCreatorAnalysis = () => {
    setShowCreatorAnalysis((prev) => !prev);
  };

  // New state to control visibility of overlays
  const [showOverlays, setShowOverlays] = useState(true);

  const formatNumber = useCallback((number) => {
    if (typeof number !== "number") return "0";
    const absNumber = Math.abs(number);
    if (absNumber >= 1e12) return `${(number / 1e12).toFixed(1)}T`;
    else if (absNumber >= 1e9) return `${(number / 1e9).toFixed(1)}B`;
    else if (absNumber >= 1e6) return `${(number / 1e6).toFixed(1)}M`;
    else if (absNumber >= 1e3) return `${(number / 1e3).toFixed(1)}K`;
    return number.toLocaleString("en-US", { maximumFractionDigits: 2 });
  }, []);

  const formatPercentage = useCallback((number) => {
    const num = typeof number === "string" ? parseFloat(number) : number;
    if (isNaN(num)) return "0%";
    return `${num.toFixed(2)}%`;
  }, []);

  useEffect(() => {
    if (nodes.length > 0 && containerRef.current) {
      containerRef.current.setAttribute("data-loaded", "true");
    }
  }, [nodes]);

  useEffect(() => {
    const handleResize = () => {
      clearTimeout(window.resizeTimeout);
      window.resizeTimeout = setTimeout(updateSize, 150);
    };
    window.addEventListener("resize", handleResize);
    updateSize();
    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(window.resizeTimeout);
    };
  }, [updateSize]);

  useEffect(() => {
    updateSize();
  }, [bundleData, hideEmptyBundles, updateSize]);

  useEffect(() => {
    if (isIframe) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.toggle("dark-mode", darkMode);
    }
  }, [darkMode, isIframe]);

  const toggleDarkMode = useCallback(() => {
    setDarkMode((prevMode) => {
      const newMode = !prevMode;
      localStorage.setItem("darkMode", JSON.stringify(newMode));
      return newMode;
    });
  }, []);

  const calculateTotalPercentageBundled = useCallback((bundles) => {
    let total = 0;
    Object.values(bundles).forEach((bundle) => {
      total += parseFloat(bundle.token_percentage);
    });
    return total.toFixed(2);
  }, []);
  const [allBundleData, setAllBundleData] = useState(null);

  const fetchBundleData = useCallback(
    async (addr) => {
      const trimmedAddr = addr.trim();
      setIsLoading(true);
      setShowOverlays(false);
      try {
        const response = await fetch(
          `https://trench.bot/api/bundle/bundle_advanced/${trimmedAddr}`
        );

        if (!response.ok)
          throw new Error(`Error fetching bundle data: ${response.statusText}`);
        const data = await response.json();

        if (!data.total_percentage_bundled) {
          data.total_percentage_bundled = calculateTotalPercentageBundled(
            data.bundles
          );
        }

        setAllBundleData(data);

        if (hideEmptyBundles) {
          const filteredBundles = {};
          Object.entries(data.bundles).forEach(([key, bundle]) => {
            if (parseFloat(bundle.holding_percentage) > 0.01) {
              filteredBundles[key] = bundle;
            }
          });
          const filteredData = {
            ...data,
            bundles: filteredBundles,
          };
          setBundleData(filteredData);
        } else {
          setBundleData(data);
        }

        setShowOverlays(true);
      } catch (error) {
        console.error("Error fetching bundle data:", error);
        toast.error(
          <span>
            <FontAwesomeIcon icon="times" /> Unable to fetch data. Please make
            sure it is a pump.fun launch.
          </span>
        );
        setAllBundleData(null);
        setBundleData(null);
      } finally {
        setIsLoading(false);
      }
    },
    [calculateTotalPercentageBundled, hideEmptyBundles]
  );

  const handleAddressSubmit = useCallback(
    (e) => {
      e.preventDefault();
      const trimmedAddress = inputAddress.trim();

      if (trimmedAddress === "") {
        toast.warn(
          <span>
            <FontAwesomeIcon icon="times" /> Please paste a valid Solana
            address.
          </span>
        );
        return;
      }

      setInputAddress(trimmedAddress);
      setShowOverlays(false);

      const basePath = isIframe
        ? `/iframe/bundles/${trimmedAddress}`
        : `/bundles/${trimmedAddress}`;
      const newUrlAddress = !hideEmptyBundles
        ? `${basePath}?all=true`
        : basePath;

      navigate(newUrlAddress, { replace: true, state: { viaToggle: true } });
      fetchBundleData(trimmedAddress);
    },
    [navigate, inputAddress, hideEmptyBundles, isIframe, fetchBundleData]
  );

  useEffect(() => {
    if (addressParam) {
      setInputAddress(addressParam);
      setHideEmptyBundles(!showAllFlag);
      if (previousAddressRef.current !== addressParam) {
        setShowOverlays(false);
        fetchBundleData(addressParam);
        previousAddressRef.current = addressParam;
      }
    }
  }, [addressParam, fetchBundleData, showAllFlag]);

  useEffect(() => {
    if (!isIframe) {
      const trimmedAddress = inputAddress.trim();
      if (
        (trimmedAddress.toLowerCase().endsWith("pump") ||
          trimmedAddress.toLowerCase().endsWith("hat")) &&
        !isLoading &&
        trimmedAddress !== addressParam
      ) {
        const basePath = `/bundles/${trimmedAddress}`;
        const newUrlAddress = !hideEmptyBundles
          ? `${basePath}?all=true`
          : basePath;
        navigate(newUrlAddress, { replace: true, state: { viaToggle: true } });
      }
    }
  }, [
    inputAddress,
    isLoading,
    navigate,
    addressParam,
    hideEmptyBundles,
    isIframe,
  ]);

  const handleInputChange = useCallback((e) => {
    const newAddress = e.target.value;
    setInputAddress(newAddress);
  }, []);

  const handleToggleShowAll = useCallback(() => {
    setHideEmptyBundles((prevHideEmpty) => {
      const newHideEmpty = !prevHideEmpty;

      if (allBundleData) {
        if (newHideEmpty) {
          // Filter out bundles with low holding percentage
          const filteredBundles = {};
          Object.entries(allBundleData.bundles).forEach(([key, bundle]) => {
            if (parseFloat(bundle.holding_percentage) > 0.01) {
              filteredBundles[key] = bundle;
            }
          });
          setBundleData({
            ...allBundleData,
            bundles: filteredBundles,
          });
        } else {
          // Show all bundles
          setBundleData(allBundleData);
        }
      }

      // Update URL
      const basePath = isIframe
        ? `/iframe/bundles/${inputAddress}`
        : `/bundles/${inputAddress}`;
      const newUrlAddress = !newHideEmpty ? `${basePath}?all=true` : basePath;
      navigate(newUrlAddress, { replace: true, state: { viaToggle: true } });

      return newHideEmpty;
    });
  }, [allBundleData, inputAddress, navigate, isIframe]);

  // Ensure the creator-analysis-icon is visible whenever bundleData is updated
  useEffect(() => {
    if (bundleData) {
      // No additional state variable needed; conditionally render based on bundleData
    }
  }, [bundleData]);

  useEffect(() => {
    if (
      bundleData &&
      bundleData.bundles &&
      containerSize.width &&
      containerSize.height
    ) {
      const bubbles = Object.entries(bundleData.bundles)
        .map(([bundleId, bundleInfo]) => {
          const tokenPercentage = parseFloat(bundleInfo.token_percentage);
          const holdingPercentage = parseFloat(bundleInfo.holding_percentage);

          if (hideEmptyBundles && holdingPercentage <= 0.01) return null;
          if (tokenPercentage <= 0) return null;

          const size = Math.max(Math.min(tokenPercentage * 30, 500), 80);

          return {
            id: bundleId,
            slot: bundleId,
            r: size / 2,
            bundleInfo: { ...bundleInfo, id: bundleId, slot: bundleId },
          };
        })
        .filter(Boolean);
      setHasVisibleBubbles(bubbles.length > 0);

      const pack = d3
        .pack()
        .size([containerSize.width, containerSize.height])
        .padding(15);

      const root = d3.hierarchy({ children: bubbles }).sum((d) => d.r * d.r);
      const packedNodes = pack(root).leaves();

      packedNodes.forEach((node) => {
        if (!node.x || !node.y) {
          node.x = containerSize.width / 2 + (Math.random() - 0.5) * 50;
          node.y = containerSize.height / 2 + (Math.random() - 0.5) * 50;
        }
      });

      setNodes(packedNodes);

      if (simulationRef.current) {
        simulationRef.current.stop();
      }

      simulationRef.current = d3
        .forceSimulation(packedNodes)
        .force(
          "center",
          d3
            .forceCenter(containerSize.width / 2, containerSize.height / 2)
            .strength(0.05)
        )
        .force(
          "collide",
          d3
            .forceCollide()
            .radius((d) => d.r + 5)
            .strength(0.8)
            .iterations(3)
        )
        .force(
          "charge",
          d3.forceManyBody().strength((d) => -d.r * 3)
        )
        .force("x", d3.forceX(containerSize.width / 2).strength(0.05))
        .force("y", d3.forceY(containerSize.height / 2).strength(0.05))
        .alphaDecay(0.02)
        .velocityDecay(0.3)
        .on("tick", () => {
          packedNodes.forEach((node) => {
            node.x = Math.max(
              node.r,
              Math.min(containerSize.width - node.r, node.x)
            );
            node.y = Math.max(
              node.r,
              Math.min(containerSize.height - node.r, node.y)
            );
          });

          window.requestAnimationFrame(() => {
            setNodes([...packedNodes]);
          });
        });

      setZoom(1);
      setPan({ x: 0, y: 0 });

      return () => {
        if (simulationRef.current) {
          simulationRef.current.stop();
        }
      };
    }
  }, [bundleData, hideEmptyBundles, containerSize]);

  const handleMouseMove = useCallback(
    (e) => {
      if (draggingNode) {
        const dx = (e.clientX - dragStart.x) / zoom;
        const dy = (e.clientY - dragStart.y) / zoom;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (!isDragging && distance > 5) {
          setIsDragging(true);
        }

        let newFx = dragStart.fx + dx;
        let newFy = dragStart.fy + dy;

        newFx = Math.max(
          draggingNode.r,
          Math.min(containerSize.width - draggingNode.r, newFx)
        );
        newFy = Math.max(
          draggingNode.r,
          Math.min(containerSize.height - draggingNode.r, newFy)
        );

        draggingNode.fx = newFx;
        draggingNode.fy = newFy;
        simulationRef.current.alpha(1).restart();
      } else if (e.buttons === 1 && !draggingNode) {
        setPan((prev) => ({
          x: prev.x + e.movementX / zoom,
          y: prev.y + e.movementY / zoom,
        }));
      }
    },
    [draggingNode, dragStart, isDragging, zoom, containerSize]
  );

  const handleMouseUp = useCallback(() => {
    if (draggingNode) {
      draggingNode.fx = null;
      draggingNode.fy = null;
      setDraggingNode(null);
      setIsDragging(false);
    }
  }, [draggingNode]);

  const handleDragStart = useCallback((e, node) => {
    e.stopPropagation();
    setDraggingNode(node);
    setDragStart({
      x: e.clientX,
      y: e.clientY,
      fx: node.fx || node.x,
      fy: node.fy || node.y,
    });
    setIsDragging(false);
  }, []);

  const handleDragEnd = useCallback(() => {
    if (draggingNode) {
      draggingNode.fx = null;
      draggingNode.fy = null;
      setDraggingNode(null);
      setIsDragging(false);
    }
  }, [draggingNode]);

  const handleBubbleClick = useCallback(
    (bundleInfo, event) => {
      if (!isDragging) {
        setSelectedBundle(bundleInfo);
      }
    },
    [isDragging]
  );

  const handleWheel = useCallback(
    (e) => {
      e.preventDefault();
      const zoomFactor = 0.1;
      const deltaZoom = e.deltaY > 0 ? -zoomFactor : zoomFactor;
      const newZoom = Math.min(Math.max(zoom + deltaZoom, 0.5), 3);
      const scale = newZoom / zoom;

      const container = containerRef.current;
      if (!container) return;
      const rect = container.getBoundingClientRect();
      const mx = e.clientX - rect.left;
      const my = e.clientY - rect.top;

      const newPanX = pan.x - (mx - pan.x) * (scale - 1);
      const newPanY = pan.y - (my - pan.y) * (scale - 1);

      setZoom(newZoom);
      setPan({ x: newPanX, y: newPanY });
    },
    [zoom, pan]
  );

  const captureScreenshot = useCallback(async () => {
    if (containerRef.current) {
      try {
        const bubbleMap = containerRef.current.querySelector(".bubble-map");
        if (!bubbleMap) throw new Error("Bubble map not found");

        const originalTransform = bubbleMap.style.transform;
        const originalTransition = bubbleMap.style.transition;
        const originalPosition = bubbleMap.style.position;

        const elementsToHide = [
          document.querySelector(".zoom-controls"),
          document.querySelector(".screenshot-button-container"),
          document.querySelector(".show-bundles-toggle"),
          document.querySelector(".night-toggle"),
          document.querySelector(".creator-analysis-icon"),
          document.querySelector(".creator-analysis-overlay"),
        ];
        const originalDisplays = elementsToHide.map((el) => el?.style.display);
        elementsToHide.forEach((el) => {
          if (el) el.style.display = "none";
        });

        const originalContainerStyle = {
          height: containerRef.current.style.height,
          overflow: containerRef.current.style.overflow,
          position: containerRef.current.style.position,
        };

        const contentHeight = bubbleMap.getBoundingClientRect().height;
        containerRef.current.style.height = `${contentHeight + 40}px`;
        containerRef.current.style.overflow = "visible";
        containerRef.current.style.position = "relative";

        bubbleMap.style.transform = "none";
        bubbleMap.style.transition = "none";
        bubbleMap.style.position = "relative";
        bubbleMap.style.left = "0";
        bubbleMap.style.top = "0";

        const bubbles = bubbleMap.querySelectorAll(".bubble");
        const originalBubbleTransforms = Array.from(bubbles).map(
          (bubble) => bubble.style.transform
        );

        bubbles.forEach((bubble) => {
          const currentTransform = bubble.style.transform;
          if (currentTransform) {
            bubble.style.transform = currentTransform.replace(
              /scale\([^)]*\)/,
              "scale(1)"
            );
          }
        });

        const dataUrl = await toPng(containerRef.current, {
          backgroundColor: "#b0b0b0",
          pixelRatio: 2,
          width: containerSize.width,
          height: contentHeight + 40,
          cacheBust: true,
          style: {
            transform: "none",
            transformOrigin: "0 0",
          },
        });

        // Restore original styles
        bubbleMap.style.transform = originalTransform;
        bubbleMap.style.transition = originalTransition;
        bubbleMap.style.position = originalPosition;

        containerRef.current.style.height = originalContainerStyle.height;
        containerRef.current.style.overflow = originalContainerStyle.overflow;
        containerRef.current.style.position = originalContainerStyle.position;

        bubbles.forEach((bubble, index) => {
          bubble.style.transform = originalBubbleTransforms[index];
        });

        elementsToHide.forEach((el, index) => {
          if (el) el.style.display = originalDisplays[index];
        });

        const response = await fetch(dataUrl);
        const blob = await response.blob();

        if (blob) {
          const url = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = "bubble_map_screenshot.png";
          a.click();
          URL.revokeObjectURL(url);

          if (navigator.clipboard && window.ClipboardItem) {
            try {
              const clipboardItem = new ClipboardItem({ "image/png": blob });
              await navigator.clipboard.write([clipboardItem]);
              toast.success(
                <span>
                  <FontAwesomeIcon icon="camera-retro" /> Screenshot copied to
                  clipboard!
                </span>
              );
            } catch (err) {
              console.error("Failed to copy image to clipboard:", err);
              toast.error(
                <span>
                  <FontAwesomeIcon icon="times" /> Failed to copy screenshot to
                  clipboard.
                </span>
              );
            }
          } else {
            toast.warn(
              <span>
                <FontAwesomeIcon icon="camera-retro" /> Clipboard API not
                supported. Screenshot downloaded instead.
              </span>
            );
          }
        }
      } catch (error) {
        console.error("Error capturing screenshot:", error);
        toast.error(
          <span>
            <FontAwesomeIcon icon="times" /> Error capturing screenshot.
          </span>
        );
      }
    }
  }, [containerSize]);

  useEffect(() => {
    function handleMessage(event) {
      if (event.origin !== window.location.origin) return;
      const { type, address } = event.data;
      if (type === "SCAN_BUNDLES" && address) {
        setInputAddress(address);
        setShowOverlays(false); // Hide overlays when receiving new address
        fetchBundleData(address);
        const basePath = isIframe
          ? `/iframe/bundles/${address}`
          : `/bundles/${address}`;
        const newUrlAddress = !hideEmptyBundles
          ? `${basePath}?all=true`
          : basePath;
        navigate(newUrlAddress, { replace: true, state: { viaToggle: true } });
      }
    }

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [fetchBundleData, hideEmptyBundles, isIframe, navigate]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    let lastTouch = null;

    const handleTouchStart = (e) => {
      if (e.touches.length === 1) {
        lastTouch = { x: e.touches[0].clientX, y: e.touches[0].clientY };
      }
    };

    const handleTouchMove = (e) => {
      if (e.touches.length === 1 && lastTouch) {
        const dx = (e.touches[0].clientX - lastTouch.x) / zoom;
        const dy = (e.touches[0].clientY - lastTouch.y) / zoom;
        setPan((prev) => ({
          x: prev.x + dx,
          y: prev.y + dy,
        }));
        lastTouch = { x: e.touches[0].clientX, y: e.touches[0].clientY };
      }
    };

    const handleTouchEnd = () => {
      lastTouch = null;
    };

    container.addEventListener("touchstart", handleTouchStart);
    container.addEventListener("touchmove", handleTouchMove);
    container.addEventListener("touchend", handleTouchEnd);

    return () => {
      container.removeEventListener("touchstart", handleTouchStart);
      container.removeEventListener("touchmove", handleTouchMove);
      container.removeEventListener("touchend", handleTouchEnd);
    };
  }, [zoom]);

  const renderCreatorAnalysis = useMemo(() => {
    if (!showCreatorAnalysis || !bundleData?.creator_analysis) return null;

    const { creator_analysis } = bundleData;
    const {
      address,
      history: { rug_count, rug_percentage, total_coins_created },
      holding_percentage,
      warning_flags,
    } = creator_analysis;

    return (
      <div
        className={`creator-analysis-overlay ${
          showCreatorAnalysis ? "open" : ""
        }`}
        aria-hidden={!showCreatorAnalysis}
        role="dialog"
        aria-labelledby="creator-analysis-title">
        <div className="creator-analysis-header">
          <h3 id="creator-analysis-title">Creator Analysis</h3>
          <button
            className="creator-analysis-close-button"
            onClick={toggleCreatorAnalysis}
            aria-label="Close Creator Analysis">
            <FontAwesomeIcon icon="times-circle" />
          </button>
        </div>
        <div className="creator-analysis-content">
          <div className="info-item">
            <FontAwesomeIcon icon="user" className="info-icon" />
            <span className="info-label">Address:</span>
            <span className="wallet-address">
              <a
                href={`https://solscan.io/account/${address}`}
                target="_blank"
                rel="noopener noreferrer">
                {address.slice(0, 4)}...{address.slice(-4)}
              </a>
            </span>
          </div>
          <div className="info-item">
            <FontAwesomeIcon icon="chart-line" className="info-icon" />
            <span className="info-label">Current Held Supply:</span>
            <span className="info-value">
              {formatPercentage(holding_percentage)}
            </span>
          </div>
          <div className="info-item">
            <FontAwesomeIcon icon="times-circle" className="info-icon" />
            <span className="info-label">Rug Count:</span>
            <span className="info-value">{rug_count}</span>
          </div>
          <div className="info-item">
            <FontAwesomeIcon icon="coins" className="info-icon" />
            <span className="info-label">Total Coins Created:</span>
            <span className="info-value">{total_coins_created}</span>
          </div>
          <div className="info-item">
            <FontAwesomeIcon icon="percent" className="info-icon" />
            <span className="info-label">Rug Ratio:</span>
            <span className="info-value">
              {formatPercentage(rug_percentage)}
            </span>
          </div>
          <div className="info-item">
            <FontAwesomeIcon icon="exclamation-circle" className="info-icon" />
            {warning_flags.filter((flag) => flag).length > 0 && (
              <>
                <span className="info-label">Warning:</span>
                <ul className="warning-flags-list">
                  {warning_flags
                    .filter((flag) => flag)
                    .map((flag, index) => (
                      <li key={index}>{flag}</li>
                    ))}
                </ul>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }, [showCreatorAnalysis, bundleData, formatPercentage, formatNumber]);

  const renderOverallInfo = useMemo(() => {
    if (!bundleData) return null;

    return (
      <div
        className={`overall-info-overlay ${
          isIframe ? "iframe-view" : "normal-view"
        }`}
        style={{ display: showOverlays ? "flex" : "none" }}>
        <div className="info-item">
          <FontAwesomeIcon icon="tag" className="info-icon icon-tag" />
          <span className="info-label">Ticker:</span>
          <span className="info-value">{bundleData?.ticker}</span>
        </div>
        <div className="info-item">
          <FontAwesomeIcon icon="box" className="info-icon icon-box" />
          <span className="info-label">Total Bundles: </span>
          <span className="info-value">{bundleData.total_bundles}</span>
        </div>
        <div className="info-item">
          <FontAwesomeIcon icon="money-bill" className="info-icon icon-money" />
          <span className="info-label">SOL Spent: </span>
          <span className="info-value">
            {formatNumber(bundleData.total_sol_spent)} SOL
          </span>
        </div>
        <div className="info-item">
          <FontAwesomeIcon icon="chart-line" className="info-icon icon-chart" />
          <span className="info-label">Bundled Total: </span>
          <span className="info-value">
            {formatPercentage(bundleData.total_percentage_bundled)}
          </span>
        </div>
        <div className="info-item">
          <FontAwesomeIcon icon="chart-line" className="info-icon icon-chart" />
          <span className="info-label">Held Percentage: </span>
          <span className="info-value">
            {formatPercentage(bundleData.total_holding_percentage)}
          </span>
        </div>
        <div className="info-item">
          <FontAwesomeIcon icon="hand" className="info-icon icon-hand" />
          <span className="info-label">Held Tokens: </span>
          <span className="info-value">
            {formatNumber(bundleData.total_holding_amount / 1e6)}
          </span>
        </div>
        <div className="info-item">
          <FontAwesomeIcon icon="link" className="info-icon icon-link" />
          <span className="info-label">Bonded: </span>
          <span className="info-value">
            {bundleData.bonded ? (
              <FontAwesomeIcon icon="check" className="icon-check" />
            ) : (
              <FontAwesomeIcon icon="times" className="icon-times" />
            )}
          </span>
        </div>

        {renderCreatorAnalysis}
      </div>
    );
  }, [
    bundleData,
    formatNumber,
    formatPercentage,
    isIframe,
    showCreatorAnalysis,
    renderCreatorAnalysis,
    showOverlays,
  ]);

  const renderBubbles = useMemo(() => {
    if (!bundleData || !bundleData.bundles || nodes.length === 0) return null;

    return nodes
      .filter((node) => {
        if (!node || !node.data || !node.data.bundleInfo) return false;
        const primaryCategory =
          node.data.bundleInfo.bundle_analysis?.primary_category || "regular";

        return (
          (primaryCategory === "new_wallet" && showNewWallet) ||
          (primaryCategory === "sniper" && showSniper) ||
          (primaryCategory === "copytrader" && showCopyTrader) ||
          (primaryCategory === "team_bundle" && showTeamBundle) ||
          (primaryCategory === "regular" && showRegular)
        );
      })
      .map((node) => {
        const { x, y, r, data } = node;
        const { id: bundleId, bundleInfo } = data;

        const holdingPercentage = parseFloat(bundleInfo.holding_percentage);
        const tokenPercentage = parseFloat(bundleInfo.token_percentage);
        const primaryCategory =
          bundleInfo.bundle_analysis?.primary_category || "regular";

        const holdingToTokenRatio =
          tokenPercentage !== 0
            ? (holdingPercentage / tokenPercentage) * 100
            : 0;
        const redPercentage = Math.min(holdingToTokenRatio, 100);

        const fontSize = Math.max(Math.min(r / 2.5, 24), 7);
        const detailsFontSize = Math.max(Math.min(r / 4, 12), 8);
        const sizeCategory = r > 80 ? "large" : r > 40 ? "medium" : "small";

        return (
          <div
            key={bundleId}
            className="bubble"
            data-size={sizeCategory}
            style={{
              width: `${r * 2}px`,
              height: `${r * 2}px`,
              transform: `translate(${x - r}px, ${y - r}px)`,
              background: `conic-gradient(${
                customStyles.bubbleColor || "var(--bubble-color)"
              } 0deg, ${
                customStyles.bubbleColor || "var(--bubble-color)"
              } ${redPercentage}%, rgb(204, 204, 204) ${redPercentage}%, rgb(204, 204, 204) 100%)`,
              cursor: draggingNode ? "grabbing" : "grab",
            }}
            onMouseDown={(e) => handleDragStart(e, node)}
            onMouseUp={handleDragEnd}
            onTouchStart={(e) => handleDragStart(e, node)}
            onTouchEnd={handleDragEnd}
            title={`Bundle ID: ${bundleId}\nToken %: ${tokenPercentage.toFixed(
              4
            )}%\nHolding %: ${holdingPercentage.toFixed(4)}%`}>
            <div className="bubble-content">
              <div className="percentage-line">
                <span
                  className="token-percentage"
                  style={{ fontSize: `${fontSize}px` }}>
                  {formatPercentage(tokenPercentage)}
                </span>
              </div>
              <div
                className="primary-category-icon"
                style={{ fontSize: `${fontSize * 0.8}px` }}>
                {getCategoryIcon(primaryCategory)}
              </div>
              <button
                className="view-details"
                style={{ fontSize: `${detailsFontSize}px` }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleBubbleClick(bundleInfo, e);
                }}
                aria-label="View Details">
                View Details
              </button>
            </div>
          </div>
        );
      });
  }, [
    nodes,
    draggingNode,
    bundleData,
    handleDragStart,
    handleBubbleClick,
    handleDragEnd,
    formatPercentage,
    customStyles.bubbleColor,
    showNewWallet,
    showSniper,
    showCopyTrader,
    showRegular,
    showTeamBundle,
  ]);

  // ----------- MODAL CODE STARTS HERE -----------
  const renderModal = useMemo(() => {
    if (!selectedBundle) return null;

    const slotNumber = selectedBundle.slot || selectedBundle.id;
    const totalSolSpent = selectedBundle.total_sol;
    const totalTokensBought = selectedBundle.total_tokens;
    const holdingAmount = selectedBundle.holding_amount;
    const tokenPercentage = selectedBundle.token_percentage;
    const holdingPercentage = selectedBundle.holding_percentage;
    const totalTokensFormatted = formatNumber(totalTokensBought / 1e6);
    const holdingAmountFormatted = formatNumber(holdingAmount / 1e6);
    const totalSolSpentFormatted = formatNumber(totalSolSpent);
    const primaryCategory =
      selectedBundle.bundle_analysis?.primary_category || "regular";

    return (
      <div
        className="modal"
        onClick={() => setSelectedBundle(null)}
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-title">
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          <button
            className="modal-close-button"
            onClick={() => setSelectedBundle(null)}
            aria-label="Close Modal">
            &times;
          </button>

          <h2 id="modal-title">Bundle Information</h2>
          <div className="info-grid">
            <div className="info-item">
              <strong>Slot:&nbsp;</strong>
              {slotNumber}
            </div>
            <div className="info-item">
              <strong>Unique Wallets:&nbsp;</strong>
              {selectedBundle.unique_wallets}
            </div>
            <div className="info-item">
              <strong>Total Tokens Bought:&nbsp;</strong>
              {totalTokensFormatted}
            </div>
            <div className="info-item">
              <strong>Total SOL Spent:&nbsp;</strong>
              {totalSolSpentFormatted} SOL
            </div>
            <div className="info-item">
              <strong>Token Percentage:&nbsp;</strong>
              {formatPercentage(tokenPercentage)}
            </div>
            <div className="info-item">
              <strong>Holding Percentage:&nbsp;</strong>
              {formatPercentage(holdingPercentage)}
            </div>
            <div className="info-item">
              <strong>Holding Amount:&nbsp;</strong>
              {holdingAmountFormatted}
            </div>
          </div>

          {selectedBundle.bundle_analysis && (
            <div className="bundle-analysis-section">
              <h3>Bundle Analysis</h3>
              <div className="info-item">
                <strong>Primary Category: </strong>
                <span
                  data-tooltip-id="category-tooltip"
                  data-tooltip-content={categoryDescriptionMap[primaryCategory]}
                  className="bundle-category-icon">
                  {getCategoryIcon(primaryCategory, true)}{" "}
                </span>
              </div>
            </div>
          )}

          <h3>Wallet Info:</h3>
          <div className="wallet-section">
            {selectedBundle.wallet_info &&
              Object.entries(selectedBundle.wallet_info).map(
                ([wallet, info]) => {
                  const walletTokensFormatted = formatNumber(info.tokens / 1e6);
                  const walletSolSpentFormatted = formatNumber(info.sol);
                  const walletTokenPercentage = formatPercentage(
                    info.token_percentage
                  );
                  const walletSolPercentage = formatPercentage(
                    info.sol_percentage
                  );
                  const walletCategory =
                    selectedBundle.wallet_categories &&
                    selectedBundle.wallet_categories[wallet];

                  return (
                    <div key={wallet} className="wallet-item">
                      <div className="wallet-address">
                        <a
                          href={`https://solscan.io/account/${wallet}`}
                          target="_blank"
                          rel="noopener noreferrer">
                          {wallet.slice(0, 8)}...{wallet.slice(-8)}
                        </a>
                        {walletCategory && (
                          <span
                            data-tooltip-id="category-tooltip"
                            data-tooltip-content={
                              categoryDescriptionMap[walletCategory]
                            }
                            className="wallet-category-icon"
                            style={{
                              marginLeft: "8px",
                              display: "inline-flex",
                              alignItems: "center",
                              gap: "4px",
                              pointerEvents: "auto",
                              cursor: "help",
                            }}>
                            {getCategoryIcon(walletCategory, true)}
                          </span>
                        )}
                      </div>
                      <div className="wallet-detail">
                        <span>Bought: {walletTokensFormatted}</span>
                        <span>({walletTokenPercentage})</span>
                      </div>
                      <div className="wallet-detail">
                        <span>SOL Spent: {walletSolSpentFormatted} SOL</span>
                        <span>({walletSolPercentage})</span>
                      </div>
                    </div>
                  );
                }
              )}
          </div>

          {/* Keep this line for the "info-tooltip" but remove the duplicate "category-tooltip". */}
          <Tooltip id="info-tooltip" place="top" />

          {/*
            REMOVED DUPLICATE:
            <Tooltip id="category-tooltip" place="top" />
          */}
        </div>
      </div>
    );
  }, [selectedBundle, formatNumber, formatPercentage]);
  // ----------- MODAL CODE ENDS HERE -----------

  return (
    <div
      className={`bubble-map-container ${isIframe ? "iframe-view" : ""}`}
      style={{
        width: "100%",
        height: "100%",
        backgroundColor:
          customStyles.containerBackground || "var(--container-background)",
        position: "relative",
      }}>
      <div className="content-wrapper">
        {/* Always display the title and search form */}
        {!isIframe && (
          <>
            <h1 className="bubble-map-title">TrenchRadar Bundle Viewer</h1>
            <form onSubmit={handleAddressSubmit} className="address-form">
              <input
                type="text"
                value={inputAddress}
                onChange={handleInputChange}
                placeholder="Enter Solana address ending in pump"
                className="address-input"
                required
                title="Enter address"
                style={{ cursor: "text" }}
              />
              <button
                className="submit-button"
                type="submit"
                aria-label="Search">
                <FontAwesomeIcon icon="search" />
              </button>
              <button
                type="button"
                className="refresh-button"
                onClick={() => fetchBundleData(inputAddress)}
                disabled={!inputAddress || isLoading}
                title="Refresh data"
                aria-label="Refresh data">
                <FontAwesomeIcon icon="rotate-right" spin={isLoading} />
              </button>
            </form>
          </>
        )}

        {/* Conditionally render the creator-analysis-icon based on bundleData */}
        {!isIframe && bundleData && (
          <button
            className="creator-analysis-icon"
            onClick={toggleCreatorAnalysis}
            aria-label="Toggle Creator Analysis"
            data-tooltip-id="creator-analysis-tooltip"
            data-tooltip-content="Toggle Creator Analysis">
            <FontAwesomeIcon icon="info-circle" className="toggle-icon" />
          </button>
        )}

        {renderOverallInfo}

        {!isIframe && (
          <button
            type="button"
            className="dark-mode-toggle night-toggle"
            onClick={toggleDarkMode}
            aria-label="Toggle dark mode">
            <FontAwesomeIcon
              icon={darkMode ? faSun : faMoon}
              className="toggle-icon"
            />
          </button>
        )}

        {isLoading ? (
          <div className="loading-message">
            <img
              src="/pump-logo.png"
              alt="Loading..."
              className="loading-image"
            />
            <p>Loading bubble map...</p>
          </div>
        ) : (
          <>
            {inputAddress.trim() === "" && (
              <div className="no-bundles-message">
                <p>
                  Please enter a Solana address ending in pump or hat to begin
                  exploring the bundles.
                </p>
              </div>
            )}
            {inputAddress.trim() !== "" && (
              <div
                ref={containerRef}
                className="bubble-container"
                onWheel={handleWheel}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}>
                <div className="bubble-map-wrapper">
                  <div
                    className="bubble-map"
                    style={{
                      transform: `translate(${pan.x}px, ${pan.y}px) scale(${zoom})`,
                      transition: draggingNode
                        ? "none"
                        : "transform 0.1s ease-out",
                      transformOrigin: "0 0",
                      position: "relative",
                      width: `${containerSize.width}px`,
                      height: `${containerSize.height}px`,
                    }}>
                    <div
                      className="watermark"
                      style={{
                        bottom: "40px",
                        right: "70px",
                        position: "absolute",
                        opacity: "0.25",
                      }}>
                      <img src="/logo.webp" alt="Watermark Logo" />
                    </div>
                    {renderBubbles}

                    {!hasVisibleBubbles && (
                      <div className="no-bundles-message">
                        <p>
                          It looks like everything held has been sold. Click the{" "}
                          <button
                            className="toggle-link"
                            onClick={handleToggleShowAll}
                            aria-label="Show empty bundles">
                            Show Empty Bundles
                          </button>{" "}
                          toggle to explore empty bundles.
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                {/* Legend Overlay */}
                <div className="legend-overlay">
                  <h3>Legend</h3>
                  <p>
                    <span
                      className="legend-color"
                      style={{
                        background:
                          customStyles.bubbleColor || "rgb(204, 34, 18)",
                      }}></span>
                    Holding
                  </p>
                  <p>
                    <span
                      className="legend-color"
                      style={{ background: "rgb(204, 204, 204)" }}></span>
                    Sold
                  </p>

                  {inputAddress.trim() !== "" && (
                    <div className="category-filters">
                      <button
                        type="button"
                        className={`category-pill ${
                          showTeamBundle ? "active" : ""
                        }`}
                        onClick={() => setShowTeamBundle((prev) => !prev)}
                        data-tooltip-id="category-tooltip"
                        data-tooltip-content={
                          categoryDescriptionMap.team_bundle
                        }>
                        {getCategoryIcon("team_bundle", true)} Team Bundle
                      </button>
                      <button
                        type="button"
                        className={`category-pill ${showRegular ? "active" : ""}`}
                        onClick={() => setShowRegular((prev) => !prev)}
                        data-tooltip-id="category-tooltip"
                        data-tooltip-content={categoryDescriptionMap.regular}>
                        {getCategoryIcon("regular", true)} Regular
                      </button>
                      <button
                        type="button"
                        className={`category-pill ${showNewWallet ? "active" : ""}`}
                        onClick={() => setShowNewWallet((prev) => !prev)}
                        data-tooltip-id="category-tooltip"
                        data-tooltip-content={
                          categoryDescriptionMap.new_wallet
                        }>
                        {getCategoryIcon("new_wallet", true)} New Wallet
                      </button>
                      <button
                        type="button"
                        className={`category-pill ${showSniper ? "active" : ""}`}
                        onClick={() => setShowSniper((prev) => !prev)}
                        data-tooltip-id="category-tooltip"
                        data-tooltip-content={categoryDescriptionMap.sniper}>
                        {getCategoryIcon("sniper", true)} Sniper
                      </button>
                      <button
                        type="button"
                        className={`category-pill ${
                          showCopyTrader ? "active" : ""
                        }`}
                        onClick={() => setShowCopyTrader((prev) => !prev)}
                        data-tooltip-id="category-tooltip"
                        data-tooltip-content={
                          categoryDescriptionMap.copytrader
                        }>
                        {getCategoryIcon("copytrader", true)} Copy Trader
                      </button>
                    </div>
                  )}
                  {/* SINGLE DECLARATION: Tooltip with variant="dark" */}
                  <Tooltip id="category-tooltip" place="top" variant="dark" />
                </div>

                {inputAddress.trim() !== "" && (
                  <>
                    <div className="show-bundles-toggle">
                      <label className="toggle-label">
                        Show Empty Bundles
                        <label className="pill-toggle">
                          <input
                            type="checkbox"
                            className="pill-checkbox"
                            checked={!hideEmptyBundles}
                            onChange={handleToggleShowAll}
                            aria-label="Toggle empty bundles visibility"
                          />
                          <span className="slider"></span>
                        </label>
                      </label>
                    </div>

                    <div className="zoom-controls">
                      <button
                        type="button"
                        className="zoom-button"
                        onClick={() =>
                          setZoom((prevZoom) => Math.min(prevZoom + 0.1, 3))
                        }
                        aria-label="Zoom In">
                        +
                      </button>
                      <button
                        type="button"
                        className="zoom-button"
                        onClick={() =>
                          setZoom((prevZoom) => Math.max(prevZoom - 0.1, 0.5))
                        }
                        aria-label="Zoom Out">
                        -
                      </button>
                    </div>

                    <div className="screenshot-button-container">
                      <button
                        type="button"
                        className="screenshot-button"
                        onClick={captureScreenshot}
                        title="Take Screenshot"
                        aria-label="Take Screenshot">
                        <FontAwesomeIcon icon="camera-retro" />
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        )}
        {renderModal}

        {/* Notice we only have ONE <Tooltip id="category-tooltip" ...> now */}
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </div>

      {/* Footer Section */}
      <footer className="footer">
        <div className="footer-content">
          <div className="footer-section">
            <span className="powered-by">Powered by &nbsp;</span>
            <a
              href="https://t.me/TrenchScannerBot"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link">
              @TrenchScannerBot
            </a>
            <a
              href="https://x.com/TrenchRadar"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-link"
              aria-label="TrenchScan on X">
              &nbsp;
              <FontAwesomeIcon icon={["fab", "x-twitter"]} />
            </a>
            <span className="footer-icons">
              &nbsp;|&nbsp; Bahr&nbsp;
              <a
                href="https://t.me/maroon280"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-link"
                aria-label="Bahr on Telegram">
                <FontAwesomeIcon icon={["fab", "telegram"]} />
              </a>
              <a
                href="https://x.com/bahrs_trench"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-link"
                aria-label="Bahr on X">
                <FontAwesomeIcon icon={["fab", "x-twitter"]} />
              </a>
              &nbsp;|&nbsp; OtterOlie&nbsp;
              <a
                href="https://t.me/otterolie"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-link"
                aria-label="OtterOlie on Telegram">
                <FontAwesomeIcon icon={["fab", "telegram"]} />
              </a>
              <a
                href="https://x.com/otterolie"
                target="_blank"
                rel="noopener noreferrer"
                className="footer-link"
                aria-label="OtterOlie on X">
                <FontAwesomeIcon icon={["fab", "x-twitter"]} />
              </a>
              &nbsp;|&nbsp;
              <a
                href="https://docs.trench.bot/"
                className="footer-link"
                target="_blank"
                rel="noopener noreferrer">
                Docs
              </a>
            </span>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default BubbleMapViewer;
